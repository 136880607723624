import React, {JSX, MutableRefObject } from "react";
import { TableColumnInterface } from "../../../TablePaginationPro";
import { RenderRowCell } from "../../../TablePaginationPro";

interface BubblesProps {
    rows: any[];
    columns: TableColumnInterface[];
    bubblesRef: MutableRefObject<HTMLDivElement | null>;
    inBubble: boolean;
}

export default function Bubbles({
    rows,
    columns,
    bubblesRef,
    inBubble
}: BubblesProps): JSX.Element {
    return (
        <div
            ref={bubblesRef}
            id={'bubblesContainer'}
            className={'overflow-auto pb-3 hidden min-w-max'}
            onMouseLeave={(): string | null => (bubblesRef.current ? bubblesRef.current.style.overflow = 'auto' : null)}
            onMouseEnter={(): string | null => (bubblesRef.current ? bubblesRef.current.style.overflow = 'hidden' : null)}
        >
            <table>
                <thead className={'sticky top-0 z-[100]'}>
                    <tr>
                        <th className={'h-[54px] bg-[#4A5BCB] text-white text-[14px] font-medium px-3'}>
                            {columns[0].headerName}
                        </th>
                    </tr>
                </thead>

                <tbody className={'border-r'}>
                    {rows.map((row: any, index: number): JSX.Element => (
                        <tr key={`bubble-${index}`}>
                            <td className={`z-[1000] text-start font-normal text-[14px] border-b border-slate-100 whitespace-nowrap px-3 ${index === 0 ? 'h-[40.5px]' : 'h-[41px]'}`}>
                                <RenderRowCell
                                    row={row}
                                    index={index}
                                    col={columns[0]}
                                    inBubble={inBubble}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}